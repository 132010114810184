/* TAMANHO DE TEXTOS */
h1,
.h1 {
  font-family: $font-light;
}

h2,
.h2 {
  font-family: $font-bold;
}

h3,
.h3 {
  font-family: $font-semi-bold;
}

/* CORES DE TEXTO */
.texto-cor-principal {
  color: #1a284d !important;
}

.texto-cor-complementar {
  color: #5ad4ff !important;
}

.texto-cor-links {
  color: #25b7f7;
}

.texto-cor-suporte {
  color: #5500ff;
}

.texto-cor-preto {
  color: #1b1b1b !important;
}

.texto-cor-cinza-escuro {
  color: #454545 !important;
}

.texto-cor-cinza-claro {
  color: #a7a7a7 !important;
}

.texto-cor-cinza {
  color: #8d8d8d !important;
}

.texto-cor-cinza-2 {
  color: #747474 !important;
}

.texto-cor-cinza-3 {
  color: #b8b9bd !important;
}

.texto-cor-branca {
  color: #fff;
}

.texto-cor-preta {
  color: #333;
}

.texto-cor-primaria {
  color: #34509b;
}

.texto-cor-secundaria {
  color: #2ea0c8;
}

.texto-cor-terciaria {
  color: #785bb1;
}

.texto-danger {
  color: #ff0000 !important;
}

.texto-danger-strong {
  color: #b30000 !important;
}

.texto-alerta-danger {
  color: #f97a7b;
}

.texto-warning {
  color: #feb700;
}

.texto-alerta-warning {
  color: #ffc784;
}

.texto-success {
  color: #54a900;
}

.texto-info {
  color: #0085ff;
}

.texto-warning-strong {
  color: #feb700;
}

.texto-transparente {
  color: transparent !important;
}

.texto-link {
  color: #0085ff;
  text-decoration: underline;
  cursor: pointer;
}

.link-texto {
  cursor: pointer;
}
.link-texto:hover {
  text-decoration: underline;
}

.texto-cinza-medio {
  color: #747474;
}

.texto-cinza-escuro {
  color: #5c5c5c;
}

/* CORES DE FUNDO */
.fundo-cor-principal {
  background-color: #1a284d !important;
}

.fundo-cor-complementar {
  background-color: #5ad4ff !important;
}

.fundo-cor-complementar-leve {
  background-color: #eaf9ff !important;
}

.fundo-cor-suporte {
  background-color: #5500ff;
}

.fundo-cor-cinza-escuro {
  background-color: #454545;
}

.fundo-cor-cinza-claro {
  background-color: #f4f4f4 !important;
}

.fundo-cor-cinza {
  background-color: #f6f6f6 !important;
}

.fundo-cor-cinza-claro-2 {
  background-color: #fafcfe !important;
}

.fundo-cor-cinza-claro-3 {
  background-color: #e4e4ea !important;
}

.fundo-cor-cinza-claro-4 {
  background-color: #dddddd;
}

.fundo-cor-branca {
  background-color: #fff;
}

.fundo-cor-preta {
  background-color: #333;
}

.fundo-cor-azul-claro {
  background-color: #d9e0f2;
}

.fundo-cor-primaria {
  background-color: #34509b;
}

.fundo-cor-secundaria {
  background-color: #2ea0c8;
}

.fundo-cor-terciaria {
  background-color: #785bb1;
}

.fundo-danger {
  background-color: #ff0000;
}

.fundo-danger-light {
  background-color: #fdeded;
}

.fundo-alerta-danger {
  background-color: #feebeb;
}

.fundo-warning {
  background-color: #fff8e6 !important;
}

.fundo-warning-strong {
  background-color: #feb700;
}

.fundo-alerta-warning {
  background-color: #fff7ed;
}

.fundo-success {
  background-color: #54a900;
}

.fundo-info {
  background-color: #0085ff;
}

.fundo-transparente {
  background-color: transparent;
}

.fundo-grayscale-hover:hover,
.grayscale-20 {
  filter: grayscale(0.2);
  -webkit-filter: grayscale(0.2);
}

.fundo-cor-azul-branco {
  background-color: #eff1f6;
}

.controle-combo-overlay-24 {
  padding-bottom: 24px !important;
}

.controle-combo-overlay-48 {
  padding-bottom: 48px !important;
}

.controle-combo-overlay-72 {
  padding-bottom: 72px !important;
}

.controle-combo-overlay-96 {
  padding-bottom: 96px !important;
}

.controle-combo-overlay-120 {
  padding-bottom: 120px !important;
}

.controle-combo-overlay-144 {
  padding-bottom: 144px !important;
}

.controle-combo-overlay-168 {
  padding-bottom: 168px !important;
}

@mixin controle-combo-overlay($valor) {
  padding-bottom: $valor !important;
}

.borda-radius-left {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.borda-radius-right {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.hide-tabs-indicator {
  .MuiTabs-indicator {
    display: none !important;
  }
}

.preencher-fundo-selected {
  .Mui-selected {
    background-color: #b3c0e5 !important;
  }
  svg {
    margin-left: -10px;
  }
  button:not(.Mui-selected) {
    svg {
      opacity: 0;
    }
  }
}

.Mui-selected {
  .selected-cor-principal {
    color: #1a284d !important;
  }
}

/* TIPOS DE TEXTOS */
.font-family-ibm-plex * {
  font-family: 'IBM Plex Sans' !important;
}

.texto-negrito {
  font-weight: 700 !important;
  font-family: 'IBM Plex Sans Bold' !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-bold-600 {
  font-weight: 600 !important;
}

.texto-leve {
  font-weight: 300 !important;
  font-family: 'IBM Plex Sans Light' !important;
}

.texto-semi-negrito {
  font-weight: 500 !important;
  font-family: 'IBM Plex Sans Semi-Bold' !important;
}

.texto-weight-600 {
  font-weight: 600 !important;
}

.font-size-1-rem {
  font-size: 1rem !important;
}

.font-size-1-1-rem {
  font-size: 1.1rem !important;
}

.font-size-1-15-rem {
  font-size: 1.15rem !important;
}

.font-size-1-2-rem {
  font-size: 1.2rem !important;
}

.font-size-1-3-rem {
  font-size: 1.3rem !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-065 {
  font-size: 0.65rem !important;
}

.font-size-075 {
  font-size: 0.75rem !important;
}

.font-size-080 {
  font-size: 0.8rem !important;
}

.font-size-085 {
  font-size: 0.85rem !important;
}

.font-size-875 {
  font-size: 0.875rem !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.hidden {
  display: none !important;
}

.texto-hidden-overflow {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.sem-quebra-linha,
.white-space-nowrap {
  white-space: nowrap !important;
}

.layout-pre {
  white-space: pre !important;
}

.layout-break-space {
  white-space: break-spaces !important;
}

.line-height-23 {
  line-height: 23px !important;
}

/* PADDINGS e MARGINS */
.pad-0 {
  padding: 0px !important;
}

.pad-top-0 {
  padding-top: 0px !important;
}

.pad-top-5 {
  padding-top: 5px !important;
}

.pad-top-10 {
  padding-top: 10px !important;
}

.pad-top-15 {
  padding-top: 15px !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}

.pad-top-30 {
  padding-top: 30px !important;
}

.pad-top-40 {
  padding-top: 40px !important;
}

.pad-top-50 {
  padding-top: 50px !important;
}

.pad-bot-0 {
  padding-bottom: 0px !important;
}

.pad-bot-5 {
  padding-bottom: 5px !important;
}

.pad-bot-10 {
  padding-bottom: 10px !important;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}

.pad-bot-30 {
  padding-bottom: 30px !important;
}

.pad-5 {
  padding: 5px;
}

.pad-7 {
  padding: 7px;
}

.pad-8 {
  padding: 8px;
}

.pad-10 {
  padding: 10px;
}

.pad-15 {
  padding: 15px !important;
}

.pad-20 {
  padding: 20px;
}

.pad-25 {
  padding: 25px;
}

.pad-35 {
  padding: 35px;
}

.pad-topbot-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pad-topbot-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pad-topbot-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pad-topbot-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pad-topbot-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pad-topbot-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pad-topbot-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pad-right-left-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.pad-right-left-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.pad-right-left-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.pad-5tb-10lr {
  padding: 5px 10px !important;
}

.pad-5tb-15lr {
  padding: 5px 15px !important;
}

.pad-left-40 {
  padding-left: 40px;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-7 {
  margin-right: 7px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-7 {
  margin-left: 7px !important;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.label-padding {
  padding-top: 5px;
  padding-bottom: 4px;
}

.box-input-padding {
  padding: 5px 0;
}

.box-circulo-60 {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 8px 0 0 0;
  font-size: 1.7rem;
}

.margin-top-label {
  margin-top: 42px !important;
}

.margin-zero {
  margin: 0 !important;
}

.margin-top-zero {
  margin-top: 0 !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-5 {
  margin: 5px;
}

.margin-15 {
  margin: 15px;
}

.margin-topbot-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-topbot-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-topbot-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin-topbot-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-neg-topleft-10 {
  margin-left: -10px !important;
  margin-top: -10px !important;
}

.margin-neg-left-2 {
  margin-left: -2px !important;
}

.margin-neg-left-10 {
  margin-left: -10px !important;
}

.margin-neg-left-45 {
  margin-left: -45px !important;
}

.margin-neg-left-70 {
  margin-left: -70px !important;
}

.margin-neg-left-65 {
  margin-left: -65px !important;
}

.margin-neg-top-2 {
  margin-top: -2px !important;
}

.margin-neg-top-5 {
  margin-top: -5px !important;
}

.margin-neg-top-10 {
  margin-top: -10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-35 {
  margin-bottom: 35px !important;
}

.height-fit-content {
  height: fit-content;
}

.height-1px {
  height: 1px;
}

.rotate-90-right {
  transform: rotate(90deg);
}

.rotate-90-left {
  transform: rotate(-90deg);
}

/* BORDAS */
.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-50pct {
  border-radius: 50% !important;
}

.border-radius-left {
  border-radius: 8px 0px 0px 8px;
}

.border-2-right-white {
  border-right: 2px solid #fff !important;
}

.border-2-right-light-gray {
  border-right: 2px solid #eff1f6 !important;
}

.border-gradient-principal {
  border-style: solid;
  border-image: linear-gradient(90deg, #5500ff 0%, #5ad4ff 100%) 1;
  border-width: 0;
}

.borda-2-width {
  border-width: 2px !important;
}

.borda-1-width {
  border-width: 1px !important;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.borda-zero {
  border: 0 !important;
}

.borda-1 {
  border: 1px solid !important;
}

.borda-2 {
  border: 2px solid;
}

.borda-dashed {
  border-style: dashed !important;
}

.borda-dotted {
  border-style: dotted !important;
}

.borda-bottom-1 {
  border-bottom: 1px solid;
}

.borda-bottom-2 {
  border-bottom: 2px solid;
}

.borda-bottom-2-gray {
  border-bottom: 2px solid #d9e0f2;
}

.borda-top-1 {
  border-top: 1px solid;
}

.borda-left-1 {
  border-left: 1px solid;
}

.borda-right-1 {
  border-right: 1px solid;
}

.borda-cor-principal {
  border-color: #1a284d;
}

.borda-cor-complementar {
  border-color: #5ad4ff;
}

.borda-cor-suporte {
  border-color: #5500ff !important;
}

.borda-cor-cinza-claro {
  border-color: #f4f4f4;
}

.borda-cor-cinza-claro-2 {
  border-color: #e4e4ea;
}

.borda-cor-cinza-claro-3 {
  border-color: #afb0c0;
}

.borda-cor-cinza-claro-4 {
  border-color: #747474 !important;
}

.borda-cor-cinza {
  border-color: #a7a7a7 !important;
}

.borda-cor-cinza-escuro {
  border-color: #454545;
}

.borda-transparente {
  border-color: transparent !important;
}

.borda-cor-error {
  border-color: #d32f2f;
}

.borda-cor-info {
  border-color: #0085ff !important;
}

.borda-cor-warning-strong {
  border-color: #feb700 !important;
}

.borda-error {
  border: 1px solid #d32f2f !important;
}

.borda-outline-none {
  outline: none !important;
}

.borda-autocomplete {
  .MuiAutocomplete-inputRoot {
    height: 40px;
  }
  input {
    height: 0.4375em !important;
  }
}

.borda-erro-autocomplete {
  fieldset {
    border: 1px solid #d32f2f !important;
  }
}

/* BOX SHADOWs */
.box-shadow-escura-1 {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
}
.box-shadow-escura-2 {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}
.box-shadow-escura-3 {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
}
.box-shadow-escura-4 {
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.15);
}
.box-shadow-escura-5 {
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.15);
}
.box-shadow-escura-5 {
  box-shadow: 0px 8px 56px rgba(0, 0, 0, 0.15);
}

.box-shadow-natural-1 {
  box-shadow: 0px 8px 8px rgba(26, 40, 77, 0.15);
}
.box-shadow-natural-2 {
  box-shadow: 0px 8px 16px rgba(26, 40, 77, 0.15);
}
.box-shadow-natural-3 {
  box-shadow: 0px 8px 32px rgba(26, 40, 77, 0.15);
}
.box-shadow-natural-4 {
  box-shadow: 0px 8px 40px rgba(26, 40, 77, 0.15);
}
.box-shadow-natural-5 {
  box-shadow: 0px 8px 48px rgba(26, 40, 77, 0.15);
}
.box-shadow-natural-6 {
  box-shadow: 0px 8px 56px rgba(26, 40, 77, 0.15);
}
.box-shadow-none {
  box-shadow: none !important;
}

/*ALINHAMENTO TEXTOS*/
.flex-box-items-centralizados {
  display: flex !important;
}

.flex-box-items-centralizados,
.items-centralizados {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.items-centralizados-direita {
  align-items: center !important;
  justify-content: right !important;
  text-align: right !important;
}

.items-centralizados-esquerda {
  align-items: center !important;
  justify-content: left !important;
  text-align: left !important;
}

.flex-box {
  display: flex;
}

.flex-box-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-direction-column {
  flex-direction: column;
}

.items-centro-verticalmente {
  align-items: center;
}

.items-com-espacamento {
  justify-content: space-between;
}

.conteudo-centro {
  align-content: center;
}

.items-esquerda {
  justify-content: flex-start !important;
}
.items-centro {
  justify-content: center !important;
}
.items-direita {
  justify-content: flex-end !important;
}

.items-final-direita {
  justify-content: end !important;
}

.items-inicio {
  align-items: flex-start !important;
}
.items-meio {
  align-items: center !important;
}
.items-fim {
  align-items: flex-end !important;
}

.texto-centro {
  text-align: center !important;
}
.texto-justificado {
  text-align: justify !important;
}
.texto-esquerda {
  text-align: left !important;
}
.texto-direita {
  text-align: right !important;
}

.word-break {
  word-break: break-word;
}

/* ESTILOS DE CONTROLE E TAMANHO */
.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100%;
}

.w-100-important {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-1-5em {
  width: 1.5em !important;
}

.w-mw-40px {
  width: 40px !important;
  min-width: 40px !important;
}

.w-mw-60px {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
}

.w-mw-75px {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important;
}

.width-height-15px {
  width: 15px;
  height: 15px;
}

.min-width-none {
  min-width: unset !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-1-2 {
  line-height: 1.2 !important;
}

.font-size-0-6rem {
  font-size: 0.6rem !important;
  line-height: 1 !important;
}

.font-size-0-7rem {
  font-size: 0.7rem !important;
  line-height: 1.15 !important;
}

.font-size-0-8rem {
  font-size: 0.8rem !important;
  line-height: 1.3 !important;
}

.font-size-0-875rem {
  font-size: 0.875rem !important;
  line-height: 1.4 !important;
}

.font-size-1-6rem {
  font-size: 1.6rem !important;
}

.font-size-2rem {
  font-size: 2rem !important;
}

.font-size-2-5rem {
  font-size: 2.5rem !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-unset {
  pointer-events: unset !important;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-none {
  display: none !important;
}

/* PERSONALIZAÇÕES DE CLASSES DO MUI */
.MuiStepLabel-alternativeLabel.MuiStepLabel-label {
  margin-top: 8px !important;
}

.MuiButtonBase-root:focus {
  outline: none !important;
}

.steps-jornada {
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  &::-webkit-scrollbar {
    height: 0px;
    background: #f4f4f4;
  }
  &::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
}

.radio-group-principal {
  label {
    margin: 0;
    padding: 8px;
  }
}

.esconde-step {
  @media (max-width: 425px) {
    display: none;
  }
}

.step-principal {
  min-width: 90px;
}

.step-principal .Mui-active {
  font-weight: 600 !important;
  font-family: 'IBM Plex Sans Semi-Bold' !important;
  color: #1a284d !important;
}

.step-principal .Mui-completed svg {
  color: #5ad4ff !important;
}

.checkbox-cor-principal.Mui-checked svg {
  color: #1a284d !important;
}

.checkbox-cor-complementar.Mui-checked svg {
  color: #5ad4ff !important;
}

.checkbox-cor-suporte.Mui-checked svg {
  color: #5500ff;
}

.checkbox-cor-cinza-escuro.Mui-checked svg {
  color: #454545 !important;
}

.checkbox-cor-cinza-claro.Mui-checked svg {
  color: #8d8d8d !important;
}

.tab-principal {
  color: #8d8d8d !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  padding-bottom: 8px !important;
  &.responsivo {
    font-size: 1rem !important;
    @media (max-width: 350px) {
      font-size: 0.843rem !important;
    }
  }
}

.tab-principal.Mui-selected {
  color: #5ad4ff !important;
  font-weight: 600 !important;
  font-family: 'IBM Plex Sans Semi-Bold' !important;
}

.grupo-botoes-separados .MuiToggleButtonGroup-grouped {
  border-radius: 4px !important;
  padding: 15px 5px !important;
}

.grupo-botoes-separados .MuiToggleButtonGroup-grouped:not(:last-of-type) {
  margin-right: 15px !important;
}

.grupo-botoes-separados .MuiToggleButtonGroup-grouped {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.grupo-botoes-principal .MuiToggleButtonGroup-grouped,
.grupo-botoes-principal .MuiButton-root {
  color: #1a284d !important;
  font-weight: 400 !important;
  font-family: 'IBM Plex Sans' !important;
}

.grupo-botoes-principal .MuiButton-root {
  padding: 15px 5px !important;
}

.grupo-botoes-principal .MuiButton-root:not(.borda-error) {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.grupo-botoes-principal .Mui-selected,
.grupo-botoes-principal .MuiButton-root.Mui-selected {
  border-color: #5ad4ff !important;
  background-color: #eaf9ff !important;
  font-weight: 500 !important;
  font-family: 'IBM Plex Sans Semi-Bold' !important;
  font-size: 1rem !important;
}

.sem-uppercase {
  text-transform: none !important;
}

.camel-case {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.btn-new:disabled {
  opacity: 0.5;
}

/* PERSONALIZAÇÕES DE ELEMENTOS ESPECIFICOS */
.input-confirmacao-codigo {
  input {
    text-align: center;
    font-size: 32px;
    letter-spacing: 5px;
    font-weight: 500;
    margin-top: 30px;
    color: black;
    outline: 0;
    outline-color: pink;
    border-color: pink;
    transform: scaleX(1) translateX(0);
    border-bottom: 2px solid #1a284d;
  }
}

.container-input-confirmacao-codigo {
  text-align: center;
  p {
    font-size: 12px;
    margin: 12px -7px 0 -7px;
  }
}

.container-box {
  padding-top: 24px;
}

.btn-confirmar-codigo {
  &:disabled {
    color: white !important;
    background-color: #a7a7a7 !important;
    opacity: 0.9 !important;
  }
}

.btn-reenviar-codigo {
  width: auto !important;
  background-color: #1a284d !important;
  color: white !important;
}

.label-link-privacidade {
  display: inline-flex !important;
  align-items: flex-start !important;

  span {
    padding: 2px 9px;
  }
}

.label-link-termos-uso {
  display: inline-flex !important;
  align-items: flex-start !important;
  margin-top: 5px;

  span {
    padding: 2px 9px;
  }
}

.label-senha-reset-password {
  margin-bottom: 15px !important;
  margin-top: -15px !important;
}

.progressbar-main {
  &.MuiLinearProgress-root {
    height: 6px !important;
    border-radius: 8px !important;
    background-color: #dddddd !important;
  }
  .MuiLinearProgress-bar {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}

.progressbar-primary {
  .MuiLinearProgress-bar {
    background-color: #0085ff !important;
  }
}
.card-produto-solucoes {
  width: 244px;
  min-width: 244px;
  border: 1px solid #5ad4ff;
  border-top: 4px solid #5ad4ff;
  border-radius: 4px;
  margin: 0 10px;

  .nomeProduto {
    height: 35px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.produtoSelecionado {
    background: aliceblue;
    button {
      color: white !important;
      background-color: #1a284d !important;
    }
  }

  &:hover {
    border: 1px solid #5ad4ff;
    border-top: 4px solid #5ad4ff;
    border-bottom: 1px solid #5ad4ff;
    box-shadow: 0px 8px 32px rgba(26, 40, 77, 0.15);
  }
}

.card-produtos-pai {
  width: 100%;
  overflow: auto;
  padding-left: 2px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    &.controle-mobile {
      justify-content: normal;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a7a7a7;
    border-radius: 8px;
  }
}

.card-simulacao-solucoes {
  border: 1px solid #5ad4ff;
  border-radius: 8px !important;
  margin-top: 35px;
  .loading-pai {
    display: flex;
    justify-content: center;
  }
  .spin-rotation {
    left: unset !important;
    top: 50% !important;
  }
}

.slider-main {
  width: 98% !important;
  height: 8px !important;
  color: #dddddd !important;

  .MuiSlider-track {
    border: none;
    background-color: #5ad4ff;
  }

  .MuiSlider-thumb {
    height: 24;
    width: 24;
    background-color: #1a284d;

    &:focus,
    &:hover,
    & .Mui-active,
    & .Mui-focusVisible {
      box-shadow: inherit;
    }

    &:before {
      display: none;
    }
  }

  .MuiSlider-mark {
    width: 8px !important;
    height: 8px !important;
    border-radius: 100% !important;
    background-color: #1a284d !important;
  }
}

.abaDocumentosAtiva {
  text-align: center;
  line-height: 33px;
  color: #5ad4ff;
  font-weight: 600;
  font-size: 1rem !important;
  user-select: none;
  border-bottom: 2px solid #5ad4ff;
}

.abaDocumentosNaoAtiva {
  text-align: center;
  line-height: 33px;
  color: #8d8d8d;
  font-size: 1rem !important;
  user-select: none;
  border-bottom: 2px solid #f4f4f4;
}

.box-documentos-sociedade {
  border: 1px solid #5ad4ff;
  border-radius: 8px !important;

  .label-icones-sociedade {
    margin-top: 25px;
    text-align: center;
    font-size: 15px;
    padding: 15px;
    background: #f5f5f5;
    svg {
      margin-top: -2px !important;
    }
    @media (max-width: 500px) {
      text-align: left;
    }
  }
}

.label-documento-aprovado {
  background: rgba(84, 169, 0, 0.1);
  border-radius: 8px;
}

.link-documento-sociedade {
  color: #1a284d;
  &:hover {
    color: #078dc8;
  }
}

.link-button-sociedade {
  color: #1a284d;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  &:hover:not(:disabled) {
    color: #078dc8;
    text-decoration: underline;
  }
  &.ajuste-font {
    font-size: 0.875rem;
  }
  .ajust-width {
    width: 100% !important;
  }
  max-width: -webkit-fill-available;
  .nome-sociedade {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
  }
}
.link-button-sociedade:disabled {
  cursor: default !important;
}

.situacao-documento-socio-aprovado {
  display: inline-flex;
  background: rgba(84, 169, 0, 0.1);
  border-radius: 8px;
  padding: 3px 10px;
  @media (min-width: 500px) {
    min-width: 247px;
  }
}

.situacao-documento-socio-pendente {
  display: inline-flex;
  background: rgba(254, 183, 0, 0.1);
  border-radius: 8px;
  padding: 3px 10px;
}

.situacao-documento-pj-pendente {
  display: inline-flex;
  background: #e1f5fe;
  border-radius: 8px;
  padding: 3px 10px;
}

.icone-situacao-socio {
  margin-right: 9px !important;
  margin-top: 2px !important;
  font-size: 25px !important;

  @media (min-width: 315px) {
    margin-left: -4px;
  }

  @media (min-width: 370px) {
    margin-left: 1px;
    margin-right: 12px !important;
  }

  @media (min-width: 420px) {
    margin-left: 5px;
    margin-right: 15px !important;
  }
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.btn-deletar-arquivo {
  &:hover {
    color: red !important;
  }
}
.grid-documentos-cliente {
  text-align: center;
  font-size: 14px;
  color: #1b1b1b;
  height: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  background-color: #f6f6f6;
  align-items: center;
  border-color: #a7a7a7 !important;
  border-style: solid;
  border-width: 1px 0;
}

.grid-container-documentos-cliente {
  padding: 25px 35px !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.btn-novo-documentos {
  border: 1px solid #5ad4ff;
  background-color: #5ad4ff;
  color: white;
  padding: 10px 25px;
  border-radius: 4px;
  font-size: 14px;
  float: right;
}

.btn-voltar-documentos {
  border: 1px solid #5ad4ff;
  background-color: white;
  color: #5ad4ff;
  padding: 10px 25px;
  border-radius: 4px;
  font-size: 14px;
  float: left;
  .svg-icon {
    color: #5ad4ff;
  }
  svg {
    font-size: 10px !important;
    height: 12px !important;
    width: 12px !important;
    margin: 0 10px;
  }
}

.tbl-head-doc-clientes {
  text-align: center;
  font-size: 14px;
  color: #1b1b1b;
  height: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  background-color: #f6f6f6;
  align-items: center;
  margin-bottom: 25px;
}

.tab-body-doc-clientes {
  text-align: left;
  padding: 25px 35px !important;
  font-size: 14px;
  color: #1b1b1b;
  height: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  align-items: left;
  margin-bottom: 25px;
}

.link-email-suporte {
  text-decoration: none;
  color: #0085ff;
}

.loader-garantia-minima-small {
  min-height: 45px !important;
  margin: -10px 0 0 -25px !important;
}

.loader-simulacao {
  .spin-rotation {
    width: 25px !important;
    height: 25px !important;
  }
}

.btn-home {
  svg {
    color: transparent !important;
    width: 24px !important;
    stroke: #b8bac6;
    margin-left: -2px;
  }

  &.active {
    svg {
      stroke: #5ad4ff;
    }
  }
}

.sidebar-hover-icon {
  svg {
    font-size: 26px;
    width: 26px;
    path {
      fill: #b8bac6;
    }
  }
  &:hover {
    svg {
      path {
        fill: #25b7f7;
      }
    }
  }
}

.scrowSlide {
  .nav-item {
    p {
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      letter-spacing: 0.00938em;
      font-size: 1rem;
    }
  }
}

.btn-crm {
  svg {
    color: transparent !important;
    width: 26px !important;
    margin-left: -2px;
  }

  &.active {
    svg {
      stroke: #5ad4ff;
    }
  }
}

.combobox_principal {
  .MuiAutocomplete-endAdornment {
    top: 50% !important;
    transform: translate(0px, -50%);
    .MuiIconButton-root {
      width: 40px !important;
      height: 40px !important;
      // margin: -6px -6px 0 0 !important;
    }
  }
}

.combobox_principal_sobreposto {
  width: 100%;
  .MuiAutocomplete-endAdornment {
    .MuiIconButton-root {
      position: relative;
      z-index: 999;
      width: 40px !important;
      height: 40px !important;
      margin: -6px -6px 0 0 !important;
    }
  }
}

.icone-bordero {
  color: #5ad4ff;
  svg {
    width: 24px;
    stroke: #5ad4ff;
  }
}

.icone-home-bordero {
  svg {
    border-radius: 8px;
  }
}

.solicitacao-analise-credito {
  .icone-home-solicitacao {
    color: #5ad4ff;
    background: #eff1f6;
    width: 45px;
    padding: 7px;
    border-radius: 8px;
    text-align: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .status-solicitacao {
    padding: 8px 12px;
    border-radius: 50px;
    background: #eff1f6;
    font-size: 16px;
    font-weight: 600;
  }
  .status-solicitacao-deferida {
    color: #6a9b34;
  }
  .status-solicitacao-indeferida {
    color: #cb444b;
  }
  .status-solicitacao-em-analise {
    color: #d88c31;
  }
}

.icon-button-contained {
  background-color: #b3c0e5 !important;
  &:hover {
    background-color: #1a284d !important;
  }
  &.Mui-disabled {
    background-color: #fff !important;
  }
}

.home-link-novo-bordero {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.min-height-85 {
  min-height: 85px;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-20vh {
  min-height: 20vh;
}

.border-box-home {
  animation: fadein 1s;
  border: none;
  border-radius: 8px !important;
  padding: 5px;
  box-shadow: none !important;
  .spin-rotation {
    top: 35% !important;
    left: 48.5% !important;
  }
}

.icon-new-dashboard {
  position: absolute;
  z-index: 2;
  left: 123px;
  padding: 1px 4px;
  font-weight: 600;
  background: #d9e0f2;
  border-radius: 4px;
  text-align: center;
  color: black;
  margin-top: -25px;
}

.sidebar-mini {
  .icon-new-dashboard {
    left: 33px !important;
  }
}

.modal-pesquisa-novo {
  animation: fadein 0.7s;
  overflow: auto;
  padding-bottom: 50px;
  z-index: 9999 !important;
  .MuiModal-backdrop {
    background: #000000;
    opacity: 50% !important;
  }
  .modal-novidades {
    height: 550px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: #1a284d;
    }
    &::-webkit-scrollbar {
      height: 7px;
      background: #6782cc;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #6782cc;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .modal-pesquisa-box {
    &.fundo-escuro {
      background-color: #1a284d !important;
    }
    &.ajuste-responvivo-cliente {
      max-width: 400px !important;
    }
    padding: 5px;
    border-radius: 16px;
    text-align: center;

    max-width: 625px;
    width: 90%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 99999;

    .btn-fechar {
      padding-top: 20px;
      color: white;
      float: right;
    }
    .titulo-modal {
      color: #5ad4ff;
      font-size: 32px;
      margin-top: 10px;
    }
    .sub-titulo-modal {
      margin-top: 20px;
      color: #ffffff;
      b {
        color: white;
      }
      margin-bottom: 30px;
    }
    .icon-cards-modal {
      color: #25b7f7;
      background-color: #eaf9ff;
      margin: -3px 10px 0 0;
    }
    .cards-body-modal {
      background: white;
      padding: 20px;
      margin: 5px;
      border-radius: 8px;
    }
    .footer-titulo-modal {
      margin-top: 25px;
      text-align: center;
      color: #ffffff;
      b {
        color: white;
      }
    }
    .footer-btn-modal {
      margin-top: 20px;
      background: #5ad4ff;
      color: #1a284d;
      font-weight: 600;
      text-transform: unset;
      padding: 10px 25px;
      font-size: 16px;
      border-radius: 8px;
      span {
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
    .ajuste-padding-responsivo {
      padding: 30px;
      @media (max-width: 425px) {
        padding: 15px;
      }
    }
    .box-numeradores {
      display: flex;
      align-items: center;
      .numeradores-circular {
        background: #5ad4ff;
        border-radius: 50%;
        min-width: 32px;
        min-height: 32px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 30px;
        color: #1a284d;
        margin-right: 10px;
      }
      .texto-numerador {
        font-size: 16px;
        color: white;
        text-align: left;
      }
    }
    .footer-btn-novidades {
      margin: 15px 0 25px 0;
      background: #5ad4ff;
      color: #1a284d;
      font-weight: 600;
      text-transform: unset;
      padding: 8px 80px;
      font-size: 16px;
      border-radius: 8px;
      span {
        margin-right: 10px;
      }
    }
    .modal-clientes {
      .btn-fechar {
        color: #1a284d;
      }
      &.ajuste-tamanho-mobile {
        max-width: 400px !important;
        @media (min-width: 315px) {
          min-height: 460px;
          .texto-descricao-esquerda {
            font-size: 30px !important;
            max-height: 50px;
            margin-top: -20px;
          }
          .texto-descricao-direita {
            text-align: left !important;
            padding-left: 25px !important;
          }
        }

        @media (min-width: 370px) {
          min-height: 540px;
          .texto-descricao-esquerda {
            font-size: 32px !important;
            max-height: 50px;
            margin-top: -90px;
          }
          .texto-descricao-direita {
            text-align: left !important;
            padding-left: 25px !important;
            margin-top: -190px;
          }
        }

        @media (min-width: 420px) {
          min-height: 620px;
        }
      }
      .texto-descricao-esquerda {
        text-align: left;
        margin-top: 15px;
        padding-left: 25px;
        font-size: 40px;
      }
      .texto-descricao-direita {
        text-align: right;
        color: #1a284d;
        padding-left: 45px;
        padding-right: 18px;
        font-size: 15px;
        line-height: 18px;
        .fundo-azul {
          background: #5ad4ff;
        }
      }
    }
    .footer-btn-clientes {
      margin: 0;
      background: #5ad4ff;
      color: #1a284d;
      font-weight: bold;
      text-transform: unset;
      padding: 8px 20px;
      border-radius: 4px;
      margin-right: 20px;
      margin-top: -8px;
      font-size: 14px;
    }
    .btn-nao-tem-interesse {
      text-align: center;
      color: #1a284d;
      font-size: 13px;
      margin-top: 8px;
      margin-right: 20px;
      padding: 8px 34px;
      text-transform: inherit;
    }
  }
}

.icone-alert-bordero {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  border: 1px solid red;
  background-color: red;
  position: absolute;
  margin: -17px 0 0 15px;
}

.icone-alert-right-top {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  border: 1px solid red;
  background-color: red;
  position: absolute;
  right: 0;
  top: 0;
  margin: -2px -1px 0 0;
}

.img-logo-onboarding {
  padding-top: 5px;
  padding-left: 10px;
}

.img-logo-login {
  margin: 50px 0;
  width: 100%;
  height: 95px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icone-resumo-simulacao {
  font-size: 16px !important;
  color: #5500ff;
  margin-left: 5px;
  margin-top: -3px;
}

#tooltip-valor,
#tooltip-qtd-parcelas,
#tooltip-valor-parcelas,
#tooltip-taxa,
#tooltip-iof,
#tooltip-cet {
  .MuiTooltip-tooltip {
    background-color: #5500ff !important;
  }
}

.link-btn-forgot {
  color: #25b7f7 !important;
  text-decoration: underline !important;
  font-size: 16px !important;
}

.link-criar-conta {
  color: #25b7f7 !important;
  text-decoration: underline !important;
  font-size: 16px !important;
}

.texto-criar-conta {
  font-size: 16px !important;
}

.box-upload {
  .cursor-pointer:not([tabindex]) {
    background: #f5f5f5;
    cursor: default !important;
    .texto-link {
      cursor: default !important;
    }
  }
  &:focus {
    border-color: #1a284d !important;
  }
}

.box-flutuante-gerente-usuario {
  width: 420px;
  height: 212px;
  background-color: transparent;
  position: fixed;
  top: 200px;
  z-index: 800;
  display: flex;
  border: 2px solid #5ad4ff;
  border-left-width: 30px !important;
  border-radius: 8px 0 0 8px;
  transition: right 0.5s;
  &.box-open {
    right: -2px;
  }
  &.box-closed {
    right: -390px;
  }
}

.titulo-box-gerente-usuario {
  font-size: 0.9rem;
  color: white;
  letter-spacing: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -30px;
  margin-bottom: -33px;
  padding: 0 15px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  font-weight: 500;
  & .MuiSvgIcon-root {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 5px;
  }
}

.box-gerente-usuario {
  width: 420px;
}

.texto-nowrap-overflow-dotted {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-x {
  overflow-x: auto !important;
}

.disabled-is-label {
  .Mui-disabled {
    color: #666666 !important;
    .MuiAutocomplete-endAdornment {
      display: none !important;
    }
    input {
      -webkit-text-fill-color: unset !important;
    }
  }
}

.disabled-sem-borda {
  .Mui-disabled {
    fieldset {
      border-color: transparent !important;
    }
  }
}

.input-disabled-cinza {
  .MuiInputBase-root.Mui-disabled {
    background-color: #ececec !important;
  }
}

.Mui-disabled {
  &.botao-principal-texto-branco {
    background-color: #dddddd !important;
    color: #fff !important;
  }
  &.botao-principal {
    background-color: #dddddd !important;
    color: #fff !important;
  }
}

.input-error {
  .MuiInputBase-root {
    border: 1px solid #d32f2f !important;
    fieldset {
      border-color: #edabab;
    }
    &:hover {
      fieldset {
        border-color: #d32f2f !important;
      }
    }
    &.Mui-focused {
      fieldset {
        border: 1px solid #931f1f !important;
      }
    }
  }
}

.ajustar-icone-direita {
  margin: -4px 0 0 5px !important;
  font-size: 1rem !important;
}

.ajustar-icone-esquerda {
  margin: -4px 5px 0 0 !important;
  font-size: 1.1rem !important;
}

.posicao-absoluta-esquerda {
  position: absolute;
  left: 0;
}

.posicao-absoluta {
  position: absolute;
}

.esconder-scroll {
  -ms-overflow-style: scroll; // IE 10+
  scrollbar-width: none; // Firefox
}
.esconder-scroll::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

// .scroll-custom::-webkit-scrollbar-track,
// .scroll-custom::-webkit-scrollbar  {
//     width: 10px;
//     background-color: #b3c0e5;
//     border-radius: 8px;
// }

// .scroll-custom::-webkit-scrollbar-thumb:vertical,
// .scroll-custom::-webkit-scrollbar-thumb:horizontal,
// .scroll-custom::-webkit-scrollbar-thumb {
//     border-radius: 8px;
//     background: #d9e0f2;
// }
.scroll-custom::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c6d0eb;
  background-color: #d9e0f2;
  border-radius: 8px;
}

.scroll-custom::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #b3c0e5;
  border-radius: 8px;
}

.scroll-custom::-webkit-scrollbar-thumb,
.scroll-custom::-webkit-scrollbar-thumb:vertical,
.scroll-custom::-webkit-scrollbar-thumb:horizontal {
  background-color: #b3c0e5;
  border: 1px solid #b3c0e5;
  border-radius: 8px;
}

.tabs-etapas-propostas {
  .Mui-disabled {
    display: none;
  }
}

.card-jornada-credito {
  animation: fadein 1s;
  @media (max-width: 900px) {
    margin-top: 30px;
    margin-left: 0;
  }
  .sub-card-jornada-credito {
    padding: 0 20px;
  }
  .btn-solicitar {
    font-size: 16px;
    color: #1a284d;
    background: #ffffff;
    border-radius: 4px;
    padding: 4px 24px 4px 24px;
    text-transform: none !important;
    font-weight: 600;
  }
  .btn-solicitar:hover {
    background: #ffffff;
  }
}

.jornada-credito {
  padding: 25px 30px;
  @media (max-width: 900px) {
    padding: 25px 20px;
  }
  .icon-voltar-jornada-credito {
    text-decoration: none;
    font-size: 14px;
    color: #1a284d;
  }
  .grid-container-descricao {
    padding-right: 35px;
    @media (max-width: 900px) {
      padding-right: 0;
    }
  }
  .itens-descricao {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .icone-card-descricao {
    color: #5ad4ff;
    background: #eaf9ff;
    border-radius: 50%;
    margin-right: 15px;
    padding: 8px;
    height: auto;
    width: 45px;
  }
}

.descricao-jornada-credito {
  box-shadow: none !important;
}

.garantias-jornada-credito {
  border-radius: 16px !important;
  background: #f6f7fb !important;
  padding: 0 4%;
  box-shadow: none !important;
  .step-1-preenchido {
    border-top: 3px solid #0085ff;
    height: 4px;
  }
  .step-1-nao-preenchido {
    border-top: 3px solid #d9e0f2;
    height: 4px;
  }
  .form-radio-group-tipo-garantia {
    width: 100%;
    .radio-group-tipo-garantia {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .radio-group {
        padding: 3px;
        background: white;
        border-radius: 4px;
        margin: 0;
        width: 48%;
        min-width: 250px;
        user-select: none;
        @media (max-width: 900px) {
          width: 100%;
          margin-bottom: 10px;
        }
        &.checked {
          border: 1px solid #5ad4ff;
          background: #d6f4ff;
          span {
            font-weight: 600;
            color: #1a284d;
          }
        }
      }
    }
  }
  .jornada-credito-step-2 {
    .icon-voltar-step-2 {
      margin-top: 10px;
      color: #0085ff;
      cursor: pointer;
    }
    .box-jornada-documentos {
      .spin-rotation {
        top: 50% !important;
      }
      .label-link-privacidade {
        margin-top: -10px !important;
      }
      .component-arquivo-upload {
        @media (min-width: 900px) {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.grid-container-finalizacao {
  padding-top: 50px;
  min-height: 522px;
  .btn-pagina-inicial {
    width: 100%;
    color: #1a284d;
    font-size: 16px;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px 24px 8px 24px;
    text-transform: none !important;
    font-weight: 600;
    border: 1px solid #1a284d;
  }
  .btn-pagina-inicial:hover {
    background: #ffffff;
  }
}

.jornada-credito-formulario-imovel {
  margin-top: 15px;
  .MuiTypography-subtitle1 {
    color: black !important;
  }
  input {
    background: white !important;
  }
  .combobox-principal {
    background: white !important;
  }
}

.input-observacao-upload {
  margin-top: 10px !important;
  width: 100%;
  margin-left: 15px !important;
  .MuiInputLabel-root {
    color: #454545 !important;
  }
  .Mui-focused {
    .MuiInputLabel-root {
      font-weight: bold;
    }
  }
}

.posicao-absoluta {
  position: absolute;
}

.esconder-scroll {
  -ms-overflow-style: scroll; // IE 10+
  scrollbar-width: none; // Firefox
}
.esconder-scroll::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

// .scroll-custom::-webkit-scrollbar-track,
// .scroll-custom::-webkit-scrollbar  {
//     width: 10px;
//     background-color: #b3c0e5;
//     border-radius: 8px;
// }

// .scroll-custom::-webkit-scrollbar-thumb:vertical,
// .scroll-custom::-webkit-scrollbar-thumb:horizontal,
// .scroll-custom::-webkit-scrollbar-thumb {
//     border-radius: 8px;
//     background: #d9e0f2;
// }
.scroll-custom::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c6d0eb;
  background-color: #d9e0f2;
  border-radius: 8px;
}

.scroll-custom::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #b3c0e5;
  border-radius: 8px;
}

.scroll-custom::-webkit-scrollbar-thumb,
.scroll-custom::-webkit-scrollbar-thumb:vertical,
.scroll-custom::-webkit-scrollbar-thumb:horizontal {
  background-color: #b3c0e5;
  border: 1px solid #b3c0e5;
  border-radius: 8px;
}

.swal-text {
  font-size: 0.9rem !important;
}

.gtm-botao-notificacoes {
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  width: 40px;
  height: 40px;
  margin: 12px 12px 0 0 !important;
}

.box-aviso-qcertifica {
  padding: 10px;
  background-color: #fff7d6;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  .label-alert {
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    .alert-icon {
      color: #feb700;
      margin-right: 15px;
    }
  }
  .text-alert {
    padding-left: 50px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
}

.line-hifen-gray {
  color: #a7a7a7;
  font-size: 40px;
  text-align: center;
  font-weight: 600;
}

.btn-main,
.btn-cor-padrao {
  color: white !important;
  background: #1a284d !important;
  &:disabled {
    background: #b3c0e5 !important;
  }
}

.btn-main {
  width: 100% !important;
  height: 48px !important;
  border-radius: 8px !important;
  text-transform: none !important;
  font-size: 16px !important;
  line-height: 1.2 !important;
}

.form-radio-group-checkpoint {
  width: 100%;
  .radio-group-tipo-checkpoint {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .radio-group-checkpoint {
      position: relative;
      overflow-y: auto;
      flex: 1;
      margin-bottom: 10px;
      padding: 3px;
      background: white;
      border-radius: 4px;
      margin: 0;
      width: 100%;
      user-select: none;
      @media (max-width: 900px) {
        width: 100%;
        margin-bottom: 10px;
      }
      &.checked {
        border: 1px solid #5ad4ff;
        background: #d6f4ff;
        span {
          font-weight: 600;
          color: #1a284d;
        }
      }
    }
  }
}

.radio-group-tipo-checkpoint {
  .MuiRadio-root {
    padding: 0px 9px !important;
  }
}

.container-radio-group {
  position: relative;
}

.radio-list {
  flex: auto !important;
  flex-direction: row !important;
  width: 100% !important;
  margin-right: 100% !important;
  margin-bottom: 20px !important;
  position: relative;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  min-height: 10px;
  max-height: 150px !important;
  padding: 0px !important;
  .MuiFormControlLabel-root {
    width: 100%;
  }
}

.MuiTypography-root {
  .MuiTypography-body1 {
    margin-bottom: -1.5 !important;
  }
}

.fixed-input-checkpoint {
  position: sticky;
  bottom: 0;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.icone-limite-disponivel {
  font-size: 21px !important;
  margin-top: 3px;
  color: #54a900;
}

.icone-titulos-pendencias {
  font-size: 21px !important;
  margin-top: 3px;
  color: #feb700;
}

.box-shadow-itens-home {
  box-shadow: 0px 8px 12px 0px #5ad4ff1f !important;
  border-radius: 8px !important;
}

.row-titulos-com-pendencias {
  background: #f4f4f4;
  border-radius: 4px;
  padding: 8px 5px;
  min-height: 40px;
}

.row-titulos-sem-pendencias {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 125px;
  text-align: center;
}

.pulse-animation {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.pulse-animation.green {
  background: white;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.pulse-animation.yellow {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    background: #ffebaf;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

.hr-divisoria-home {
  background: #d9e0f2;
  height: 1px;
  margin: 0;
}

.itens-card-home {
  min-height: 100%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #5ad4ff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  max-width: 55px;
}

.itens-card-home-chevron {
  min-height: 100%;
  display: flex;
  color: #a7a7a7;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  svg {
    font-size: 30px;
  }
}

.itens-card-texto-home {
  text-decoration: underline;
  background: white;
  margin-bottom: 15px;
}

.itens-card-titulo-home {
  background: white;
  font-weight: bold;
}

.img-itens-home {
  background: white;
  min-height: 120px;
  padding-bottom: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.card-shadow-hover {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.btn-filter-clientes {
  border: 1px solid #1a284d !important;
  padding: 2px !important;
  min-width: 45px !important;
  margin-right: 5px !important;
  &:disabled {
    border: 1px solid #ccc !important;
  }
}

.div-fundo-escuro-fita {
  position: fixed;
  color: #b21a61;
  top: 70px;
  right: 0;
  img {
    height: 70px;
    width: 70px;
  }
}

.cor-fundo-personalizado {
  background: linear-gradient(to bottom, #fce2ee, #fbd0e3, #fbbed8, #f9abcc, #f898bf);
}

.link-logout {
  width: 100% !important;
  text-transform: none !important;
  justify-content: start !important;
  text-decoration: underline !important;
  &.hover {
    text-decoration: underline !important;
  }
}
